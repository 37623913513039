.bar-with-bonuses__container {
  position: relative;
  width: 100%;
  display: flex;
  top: 1%;
  margin-bottom: 1.5%;
  transition: 1.4s;
}

.bar-with-bonuses__bg-container {
  position: absolute;
  width: 163vw;
  height: 8vh;
  margin-left: 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.bar-with-bonuses__bg-fill-container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: 1.4s;
}

.bar-with-bonuses__bg-fill-container-mask {
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 1.4s;
}

.bar-with-bonuses__bar-bg-edge {
  width: 25%;
}

.bar-with-bonuses__bar-bg {
  width: 25%;
}

.bar-with-bonuses__bar {
  padding: 1% 0% 1% 0.7%;
  height: 80%;
  object-fit: fill;
}

.bar-with-bonuses__bonus.is-earned > img {
  filter: brightness(80%);
}

.bar-with-bonuses__bonus.is-earned > div {
  opacity: 0.8;
}

.bar-with-bonuses__cloud {
  position: absolute;
  top: 110%;
  width: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar-with-bonuses__cloud-points {
  width: 100%;
  z-index: 60;
}

.bar-with-bonuses__cloud-text {
  position: absolute;
  top: 25%;
  color: white;
  font-size: 2.8vw;
  text-shadow: 3px 3px 3px rgba(42, 0, 42, 1);
  z-index: 61;
}

.bar-with-bonuses__coin-prize {
  position: absolute;
  width: 7%;
  z-index: 85;
  top: 10%;
}

.bar-with-bonuses__chest-prize {
  position: absolute;
  width: 8%;
  z-index: 85;
  top: 11%;
}
