.park-enterance-dashboard__background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.park-enterance-dashboard__hero-title-container {
  position: absolute;
  top: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.park-enterance-dashboard__hero-title {
  color: white;
  font-family: "Marvin";
  font-size: 5vh;
}

.park-enterance-dashboard__hero-icon {
  width: 12vw;
  max-width: 12vw;
  max-height: 12vh;
  object-fit: contain;
}

.park-enterance-dashboard__hero-body {
  position: absolute;
  right: 2%;
  top: 48%;
  max-height: 33%;
  width: 45vw;
  height: 29vh;
  object-fit: contain;
}

.park-enterance-dashboard__coins-amount {
  position: absolute;
  color: white;
  font-family: "Futura";
  font-size: 2.6vh;
  top: 83%;
  left: 31%;
}

.park-enterance-dashboard__points-amount {
  position: absolute;
  color: white;
  font-family: "Futura";
  font-size: 2.6vh;
  top: 83%;
  left: 60%;
}

.park-enterance-dashboard__ranking-tekst {
  position: absolute;
  color: white;
  font-family: "Marvin";
  font-size: 3.3vh;
  top: 90%;
  text-align: center;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.park-enterance-dashboard__ranking-place-text {
  color: white;
  font-family: "Marvin";
  font-size: 6vh;
  margin: 0% 2%;
}

.park-enterance-dashboard__stat-value {
  position: absolute;
  color: white;
  font-family: "Marvin";
  font-size: 2.8vh;
  right: 52%;
}

.park-enterance-dashboard__ranking-flash {
  position: absolute;
  left: -5%;
  z-index: 65;
  width: 80%;
}

.park-enterance-dashboard__ranking-flash > img {
  width: 100%;
}
