.levels-info__level-info-container {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  margin-top: 1%;
  margin-left: 6%;
  margin-bottom: 1%;
  margin-right: 4%;
}

.levels-info__left-level {
  display: flex;
  align-items: center;
  z-index: 75;
}

.levels-info__col {
  display: flex;
  flex-flow: column;
  margin: 0% 5%;
}

.levels-info__level-name {
  color: white;
  font-weight: 600;
  text-shadow: 5px 5px 5px rgba(42, 0, 42, 1);
}

.levels-info__level-number {
  color: white;
  font-size: 1.8vw;
  text-shadow: 3px 3px 3px rgba(42, 0, 42, 1);
}

.levels-info__level-icon {
  position: relative;
  margin-right: 2%;
}

.levels-info__level-symbol-icon > img {
  min-width: 10vw;
  min-height: 7vh;
  object-fit: contain;
}

.levels-info__level-icon > img {
  max-width: 85%;
}
