.progress-bar__container {
  display: flex;
  flex-flow: column;
  justify-content: center;
  top: -22.5%;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.progress-bar__progress-flash {
  position: absolute;
  z-index: 65;
  width: 20%;
  top: 48%;
}

.progress-bar__progress-flash > img {
  width: 200%;
}
